/* eslint-disable @typescript-eslint/no-explicit-any */
'use client'

import type React from 'react'
import { useEffect, useMemo, useState } from 'react'

import Button from '@components/interaction/button'
import Link from 'next/link'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import ReCaptchaWrapper from '@components/layout/grecaptcha-wrapper'
import { useWindowSize } from '@components/hooks/use-window-size'
import {
  FormRenderer,
  FormSpy,
  useFormApi,
} from '@data-driven-forms/react-form-renderer'
import { type SignUpRequest } from '@lib/types'
import { type SignUpResponse } from '@app/api/sign-up/route'
import {
  componentMapper,
  TwoColumns,
  signUpSchema as schema,
  useUtmInitialValues,
} from './data-driven-form'

interface SignUpProps {
  className?: string
  toggleBoarderGrey?: boolean
}

const FormTemplate = ({ formFields }: any) => {
  const { getState, handleSubmit } = useFormApi()
  const { submitting, submitSucceeded } = getState()

  return (
    <form
      onSubmit={async (event) => {
        event.preventDefault()
        await handleSubmit() // Need this to trigger parent onSubmit to have form validation then we can submit to API
      }}
      className='2xl:p-12 mx-auto bg-white p-10 py-5 lg:ml-20 lg:mr-0 lg:max-w-xl lg:shadow-md'
    >
      {...formFields.filter((field: any) => field.name !== 'EmailOptIn')}
      {formFields.find((field: any) => field.name === 'EmailOptIn')}
      <FormSpy>
        {() => (
          <Button
            disabled={submitting || submitSucceeded}
            className='px-8 py-2 font-bold'
            variant='contained'
            size='lg'
            label={submitSucceeded ? <>Signed Up &#x2713;</> : 'Sign Up'}
            type='submit'
            loading={submitting}
            colour='secondary'
          />
        )}
      </FormSpy>

      <p className='my-6 text-body4'>
        You may unsubscribe from these communications at any time. For more
        information on how to unsubscribe, our privacy practices, and how we are
        committed to protecting and respecting your privacy, please visit the
        Privacy Policy. This site is protected by reCAPTCHA and the Google
        <a href='https://policies.google.com/privacy'> Privacy Policy</a> and
        <a href='https://policies.google.com/terms'> Terms of Service</a> apply.
        By clicking Sign Up, you agree to our{' '}
        <Link href={'/privacy'}>Privacy Policy</Link>.
      </p>
    </form>
  )
}

const SignUp: React.FC<SignUpProps> = ({ className }) => {
  const size = useWindowSize()
  const isMobile = size.width != null && size.width < 780

  const initialValues = useUtmInitialValues()
  const mobileFriendlyMapperAndSchema = useMemo(
    () =>
      isMobile
        ? {
            componentMapper,
            schema: {
              fields: [
                ...(schema.fields[0] as any).fields,
                ...schema.fields.slice(1),
              ],
            },
          }
        : {
            componentMapper: {
              ...componentMapper,
              'two-columns': TwoColumns,
            },
            schema,
          },
    [isMobile],
  )

  const [response, setResponse] = useState<SignUpResponse | null>(null)

  const { executeRecaptcha } = useGoogleReCaptcha()

  useEffect(() => {
    if (response != null) {
      alert(response.message)
    }
  }, [response])

  const handleApiSubmit = async (values: any) => {
    try {
      const token = executeRecaptcha && (await executeRecaptcha('signup'))
      if (!token) {
        setResponse({ message: 'Failed to Send, invalid recaptcha token' })
        return
      }

      const data: SignUpRequest = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        jobTitle: values.jobTitle,
        company: values.companyName,
        country: values.country,
        emailOptIn: values.subscribe,
        token,
        utmSource: values?.utmSource,
        utmMedium: values?.utmMedium,
        utmCampaign: values?.utmCampaign,
        utmTerm: values?.utmTerm,
      }
      await fetch('/api/sign-up', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
    } catch {
      setResponse({ message: 'Failed to Send' })
    }
  }

  return (
    <div className={`${className} h-fit py-16`}>
      <div className='justify-between px-5 lg:flex lg:px-0'>
        <div className='flex-1 text-left text-[40px] font-light opacity-80 max-lg:mb-4 lg:max-w-sm'>
          <p>Want the latest insights straight to your inbox?</p>
          <h2 className='mt-2'>Sign up.</h2>
        </div>
        <FormRenderer
          schema={mobileFriendlyMapperAndSchema.schema}
          componentMapper={mobileFriendlyMapperAndSchema.componentMapper}
          FormTemplate={FormTemplate}
          initialValues={{
            ...initialValues,
            subscribe: true,
          }}
          onSubmit={async (values) => {
            await handleApiSubmit(values)
          }}
        />
      </div>
    </div>
  )
}

const WrappedSignUp: React.FC<SignUpProps> = (props) => (
  <ReCaptchaWrapper>
    <SignUp {...props} />
  </ReCaptchaWrapper>
)

export default WrappedSignUp
